import axios from 'axios';
import { googleLogout } from '@react-oauth/google';

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: 'USER_LOGIN_REQUEST',
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const authFunctionUrl = `https://rg-law-gpt-be-auth2.azurewebsites.net/api/lawgptuserAuth?username=${username}&password=${password}`;
    const { data } = await axios.get(authFunctionUrl, config);
    localStorage.setItem('userInfo', JSON.stringify(data));
    dispatch({
      type: 'USER_LOGIN_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAIL',
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
  googleLogout();
  dispatch({ type: 'USER_LOGOUT' });
  window.location.replace('/');
};
