import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { Box, Stack } from '@mui/system';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { logout } from '../../actions/userActions';
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance, accounts } = useMsal();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  // Function to find the AccountInfo based on homeAccountId
  const findAccountByHomeAccountId = (homeAccountId) => {
    return accounts.find((account) => account.homeAccountId === homeAccountId);
  };

  const signOutClickHandler = (instance, accountToSignOut) => {
    instance.logout({
      account: accountToSignOut,
      postLogoutRedirectUri: '/',
    });
  };

  const logOut = () => {
    const homeAccountId = accounts[0]?.homeAccountId;
    const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
    if (accountToSignOut) {
      signOutClickHandler(instance, accountToSignOut);
    } else {
      console.error('Account not found for the given homeAccountId');
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    logOut();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#101936',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Link to="/app/chat">
            <img
              style={{ width: '125px', height: '40px' }}
              src="/images/lawgpt.png"
              alt="df-logo"
            />
          </Link>
        </Box>

        <Stack direction="row" paddingRight="0">
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {userInfo?.name}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ paddingRight: 0 }}
          >
            <Avatar
              sx={{ width: '40px', height: '40px' }}
              alt="user"
              src="/images/userPhoto.jpg"
            />
          </IconButton>
        </Stack>
      </Toolbar>
      <Menu
        sx={{ marginTop: 5 }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
