import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/system';

const MsAuth = () => {
  const { instance } = useMsal();

  const handleMicrosoftLogin = () => {
    instance.loginRedirect({
      scopes: ['user.read'],
      redirectUri: `${window.location.origin}/app/legal-chat`,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        backgroundColor: '#fff',
        border: '1px solid #dadce0',
        borderRadius: '3px',
        cursor: 'pointer',
      }}
    >
      <img
        src="/images/ms_logo.png"
        alt="microsoft"
        width="20px"
        height="20px"
      />
      <button
        style={{
          border: 'none',
          cursor: 'pointer',
          backgroundColor: '#fff',
          marginLeft: '6px',
          fontFamily: 'Roboto',
          fontSize: '14px',
        }}
        type="button"
        onClick={handleMicrosoftLogin}
      >
        Sign in with Microsoft
      </button>
    </Box>
  );
};

export default MsAuth;
