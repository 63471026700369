import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Divider,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import config from '../config/api';
import { getPetitionTitles } from '../actions/petitionActions';
import { Stack } from '@mui/system';
import PetitionEditor from './PetitionEditor';

const PetitionForm = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const petitionHistory = useSelector((state) => state.petitionHistory);
  const { loading: historyLoading, history } = petitionHistory;

  const newPetition = useSelector((state) => state.newPetition);
  const { isClicked } = newPetition;

  const deletePetitionStatus = useSelector((state) => state.deletePetition);
  const { success: successDelete } = deletePetitionStatus;

  const [finalChatId, setFinalChatId] = useState('');
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    petitioners: '',
    respondents: '',
    title: '',
    advocate: '',
    affidavit: '',
    grounds: '',
    prayer: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState('');
  const [references, setReferences] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    } else {
      // Set the error for this field if it is required and has no value
      if (
        [
          'petitioners',
          'respondents',
          'title',
          'advocate',
          'prayer',
          'description',
        ].includes(name)
      ) {
        setErrors({
          ...errors,
          [name]: 'This field is required',
        });
      }
    }
  };

  const petitionGeneration = async () => {
    const endpoint = `${config.petitionUrl}/petition_generation`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        user_id: userInfo._id,
        chat_id: finalChatId,
        temperature: 'precise',
        answer_length: 'high',
        query: {
          petitioners: formValues.petitioners,
          respondents: formValues.respondents,
          petition_title: formValues.title,
          description: formValues,
          prayer: formValues.prayer,
          advocate_name: formValues.advocate,
        },
      }),
    });
    if (response.ok) {
      const responseData = await response.json();
      setResponse(responseData.response);
      setReferences(responseData.references);
      dispatch(getPetitionTitles(userInfo._id));
      setIsLoading(false);
      handleClear();
    } else {
      setIsLoading(false);
    }
  };

  const handleGenerate = () => {
    const newErrors = {};
    const requiredFields = [
      'petitioners',
      'respondents',
      'title',
      'advocate',
      'prayer',
      'description',
    ];
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        newErrors[field] = 'This field is required';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setIsLoading(true);
      // Add logic to handle the Generate button click
      petitionGeneration();
      console.log('Form submitted', formValues);
    }
  };

  const handleClear = () => {
    setFormValues({
      petitioners: '',
      respondents: '',
      title: '',
      advocate: '',
      affidavit: '',
      grounds: '',
      prayer: '',
      description: '',
    });
    setErrors({});
  };

  const formatResponse = (responseText) => {
    const formattedResponse = responseText.split('\n').map((line, index) => {
      if (line.startsWith('# ')) {
        return (
          <Typography variant="h4" component="h1" key={index} gutterBottom>
            {line.substring(2)}
          </Typography>
        );
      }
      if (line.startsWith('## ')) {
        return (
          <Typography variant="h5" component="h2" key={index} gutterBottom>
            {line.substring(3)}
          </Typography>
        );
      }
      if (/^\d+\./.test(line)) {
        return (
          <List key={index} sx={{ listStyleType: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={line.substring(3)} />
            </ListItem>
          </List>
        );
      }
      if (line.trim() === '') {
        return <br key={index} />;
      }
      if (
        line.startsWith('Petitioner') ||
        line.startsWith('Respondent') ||
        line.startsWith('Advocate')
      ) {
        return (
          <Typography variant="body1" key={index} paragraph align="center">
            {line}
          </Typography>
        );
      }
      return (
        <Typography variant="body1" key={index} paragraph>
          {line}
        </Typography>
      );
    });
    return formattedResponse;
  };

  const generateDocx = (content) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: content.split('\n').map(
            (line) =>
              new Paragraph({
                children: [new TextRun(line)],
              })
          ),
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'petition.docx');
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = () => {
    return;
  };

  useEffect(() => {
    if (historyLoading) {
      setIsHistoryLoading(true);
    }
  }, [historyLoading]);

  useEffect(() => {
    if (history) {
      setIsHistoryLoading(false);
      const generatedResponse = history.messages[1].response;
      const references = history.messages[1].references;
      setReferences(references);
      setResponse(generatedResponse);
    }
  }, [history]);

  useEffect(() => {
    if (isClicked || successDelete || !history) {
      setResponse('');
      dispatch({ type: 'DELETE_PETITION_RESET' });
      dispatch({ type: 'NEW_PETITION_RESET' });
    }
  }, [isClicked, successDelete, history, dispatch]);

  useEffect(() => {
    const newChatId = uuidv4();
    setFinalChatId(newChatId);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90%',
        //width: '100%',
        flex: 5,
        p: 2,
        overflow: 'auto',
        letterSpacing: '0',
        lineHeight: '0',
      }}
    >
      {!response && !isHistoryLoading && (
        <Box
          border={1}
          borderRadius={4}
          borderColor="grey.400"
          bgcolor="white"
          maxWidth={750}
          width="100%"
          boxShadow={3}
          lineHeight={0}
          p={2}
          mt={2}
        >
          {Object.keys(errors).length > 0 && (
            <Box mt={2}>
              <Alert severity="error">
                Please fill in all required fields.
              </Alert>
            </Box>
          )}
          <Typography variant="h5" mb={2} align="center">
            Petition Form
          </Typography>
          <Divider />
          <form noValidate>
            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Petitioners"
                  variant="outlined"
                  margin="normal"
                  name="petitioners"
                  value={formValues.petitioners}
                  onChange={handleInputChange}
                  error={!!errors.petitioners}
                  helperText={errors.petitioners}
                />
                <TextField
                  fullWidth
                  required
                  label="Petition Title"
                  variant="outlined"
                  margin="normal"
                  name="title"
                  value={formValues.title}
                  onChange={handleInputChange}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Respondents"
                  variant="outlined"
                  margin="normal"
                  name="respondents"
                  value={formValues.respondents}
                  onChange={handleInputChange}
                  error={!!errors.respondents}
                  helperText={errors.respondents}
                />
                <TextField
                  fullWidth
                  required
                  label="Advocate Name"
                  variant="outlined"
                  margin="normal"
                  name="advocate"
                  value={formValues.advocate}
                  onChange={handleInputChange}
                  error={!!errors.advocate}
                  helperText={errors.advocate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Grounds"
                  variant="outlined"
                  margin="normal"
                  name="grounds"
                  value={formValues.grounds}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Prayer or Relief Sought"
                  variant="outlined"
                  margin="normal"
                  name="prayer"
                  value={formValues.prayer}
                  onChange={handleInputChange}
                  error={!!errors.prayer}
                  helperText={errors.prayer}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Affidavit"
                  variant="outlined"
                  margin="normal"
                  name="affidavit"
                  value={formValues.affidavit}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  required
                  label="Case Description"
                  variant="outlined"
                  margin="normal"
                  name="description"
                  rows={4}
                  multiline
                  value={formValues.description}
                  onChange={handleInputChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerate}
                sx={{
                  textTransform: 'none',
                  marginRight: 2,
                  backgroundColor: '#101936',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                    color: '#fff',
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  'Generate'
                )}
              </Button>
              <Button
                variant="outlined"
                sx={{ textTransform: 'none' }}
                color="error"
                onClick={handleClear}
              >
                Clear
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {isHistoryLoading ? (
        <Box
          sx={{
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        response &&
        !isEditing && (
          <Box mt={2} maxWidth={750} width="100%">
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h5">Generated Petition</Typography>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '10px',
                }}
                justifyContent="flex-end"
                gap={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#101936',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                      color: '#fff',
                    },
                  }}
                  startIcon={<EditIcon fontSize="small" />}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#101936',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                      color: '#fff',
                    },
                  }}
                  startIcon={<FileDownloadIcon fontSize="small" />}
                  onClick={() => generateDocx(response)}
                >
                  Download
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Box
              border={1}
              borderRadius={3}
              borderColor="grey.400"
              bgcolor="white"
              boxShadow={3}
              p={2}
              mt={2}
            >
              {formatResponse(response)}
              <Typography variant="h5" component="h2" gutterBottom>
                References
              </Typography>
              {references?.map((ref, index) => {
                return (
                  <Box marginBottom="16px">
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {`${index + 1}-${ref.label}`}
                    </Typography>
                    <Typography sx={{ paddingLeft: '1rem' }}>
                      {ref.content}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )
      )}
      {isEditing && (
        <Box
          bgcolor="white"
          maxWidth={850}
          width="100%"
          boxShadow={2}
          lineHeight={0}
          p={2}
          mt={2}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5">Edit Petition</Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
              justifyContent="flex-end"
              gap={2}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#101936',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                    color: '#fff',
                  },
                }}
                startIcon={<SaveIcon fontSize="small" />}
                onClick={handleSave}
                disabled
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#101936',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                    color: '#fff',
                  },
                }}
                startIcon={<ClearIcon fontSize="small" />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ marginBottom: '14px' }} />
          <PetitionEditor initialContent={response} />
        </Box>
      )}
    </Box>
  );
};

export default PetitionForm;
