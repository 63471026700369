import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

const GoogleAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseMessage = async (response) => {
    const credential = response.access_token;
    try {
      const userProfile = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
        }
      );
      const userProfileResponse = await userProfile.json();
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createUserWithGoogle',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: userProfileResponse.name,
            email: userProfileResponse.email,
          }),
        }
      );
      const data = await response.json();
      localStorage.setItem('userInfo', JSON.stringify(data));
      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data });
      navigate('/app/legal-chat');
    } catch (error) {
      console.log(error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => responseMessage(tokenResponse),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        backgroundColor: '#fff',
        border: '1px solid #dadce0',
        borderRadius: '3px',
        cursor: 'pointer',
      }}
    >
      <img
        src="/images/google_logo.png"
        alt="microsoft"
        width="20px"
        height="20px"
      />
      <button
        style={{
          border: 'none',
          cursor: 'pointer',
          backgroundColor: '#fff',
          marginLeft: '6px',
          fontFamily: 'Roboto',
          fontSize: '14px',
        }}
        type="button"
        onClick={() => login()}
      >
        Sign in with Google
      </button>
    </Box>
  );
};

export default GoogleAuth;
