import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Facet from '../UI/Facet';

const LegalSearchSidebar = ({ handleFilters }) => {
  const facetsData = [
    {
      category: 'Sections',
      options: [
        'PLD',
        'CLC',
        'YLR',
        'CLCN',
        'PLC(CS)N',
        'PCRLIN',
        'PLCN',
        'YLRN',
        'GBLR',
        'CLD',
        'MLD',
        'PLC',
        'PLC(CS)',
        'PCRLI',
        'SCMR',
        'PTD',
      ],
    },
    {
      category: 'Courts',
      options: [
        'Supreme Court of Pakistan',
        'Islamabad High Court',
        'Karachi High Court',
        'Lahore High Court',
        'Peshawar High Court',
        'Quetta High Court',
        'Azad Kashmir High Court',
      ],
    },
    { category: 'Year' },
    {
      category: 'Case Title',
      options: [
        'State vs. Ahmed Khan',
        'Fatima Bibi vs. Ali Raza',
        'Hassan & Sons vs. Zain Ul Abideen',
        'High Court Appeal No. 12345',
        'Supreme Court Petition No. 67890',
      ],
    },
    {
      category: 'Judge Name',
      options: [
        'Justice Asif Saeed Khosa',
        'Justice Gulzar Ahmed',
        'Justice Ijaz Ul Ahsan',
        'Justice Ayesha Malik',
        'Justice Maqbool Baqar',
      ],
    },
    {
      category: 'Lawyer Name',
      options: [
        'Abdul Ghafoor',
        'Ahmad Awais',
        'Aitzaz Ahsan',
        'A.K. Brohi',
        'Babar Awan',
      ],
    },
    {
      category: 'Applicant Name',
      options: [
        'Muhammad Aslam',
        'Khadija Tariq',
        'Usman Ali',
        'Nadia Shah',
        'Bilal Ahmed',
      ],
    },
    {
      category: 'Opponent Name',
      options: [
        'Imran Hussain',
        'Sadia Khalid',
        'Faisal Khan',
        'Rabia Awan',
        'Zahid Mehmood',
      ],
    },
    {
      category: 'Act/Ordinance',
      options: [
        'Pakistan Penal Code, 1860',
        'Code of Criminal Procedure, 1898',
        'Contract Act, 1872',
        'Family Courts Act, 1964',
        'Companies Ordinance, 1984',
      ],
    },
  ];

  const [openFacets, setOpenFacets] = useState({});
  const [selectedValues, setSelectedValues] = useState({ Sections: [] });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const hasNoValues = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return true;
    }
    const { Sections, ...rest } = obj;
    const isSectionsEmpty = Array.isArray(Sections) && Sections.length === 0;
    const hasNoOtherKeys = Object.keys(rest).length === 0;
    return isSectionsEmpty && hasNoOtherKeys;
  };

  const handleApplyFilter = () => {
    setIsClicked(true);
    handleFilters(selectedValues);
  };

  const handleClearFilters = () => {
    setIsClicked(false);
    setSelectedValues({
      Sections: [],
    });
    handleFilters(null);
  };

  const handleToggleFacet = (category) => {
    setOpenFacets((prevOpenFacets) => ({
      ...prevOpenFacets,
      [category]: !prevOpenFacets[category],
    }));
  };

  const handleChange = (category, value) => {
    if (category === 'Sections') {
      setSelectedValues((prevSelectedValues) => {
        const currentValues = prevSelectedValues[category] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value];
        return { ...prevSelectedValues, [category]: newValues };
      });
    } else {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [category]: value,
      }));
    }
  };

  useEffect(() => {
    setIsFilterApplied(hasNoValues(selectedValues));
  }, [selectedValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1.2,
        minWidth: '300px',
        width: '300px',
        p: '8px 8px 0 12px',
        bgcolor: '#F5F5F5',
        //bgcolor: ' #f0f5fa',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          mt: '20px',
          mb: isFilterApplied ? '8px' : '2px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Filters</Typography>
        <Button
          variant="contained"
          size="small"
          disabled={isFilterApplied || isClicked}
          sx={{
            textTransform: 'none',
            bgcolor: '#213165',
            '&:hover': {
              backgroundColor: '#303f9f',
            },
            '&:disabled': {
              backgroundColor: 'rgba(48, 63, 159, 0.8)',
              color: '#fff',
            },
          }}
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Button>
      </Box>
      {!isFilterApplied && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            color="error"
            sx={{
              mb: '8px',
              p: '1px',
              textTransform: 'none',
            }}
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
        </Box>
      )}

      {facetsData
        .filter((facet) =>
          ['Sections', 'Courts', 'Year'].includes(facet.category)
        )
        .map((facet) => (
          <Facet
            key={facet.category}
            facet={facet}
            openFacets={openFacets}
            handleToggleFacet={handleToggleFacet}
            handleChange={handleChange}
            selectedValues={selectedValues}
          />
        ))}
      <Button
        variant="text"
        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
        sx={{ mt: 1, textTransform: 'none' }}
      >
        {showAdvancedFilters
          ? 'Hide Advanced Filters'
          : 'Show Advanced Filters'}
      </Button>
      {showAdvancedFilters &&
        facetsData
          .filter(
            (facet) => !['Sections', 'Courts', 'Year'].includes(facet.category)
          )
          .map((facet) => (
            <Facet
              key={facet.category}
              facet={facet}
              openFacets={openFacets}
              handleToggleFacet={handleToggleFacet}
              handleChange={handleChange}
              selectedValues={selectedValues}
            />
          ))}
    </Box>
  );
};

export default LegalSearchSidebar;
