import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, IconButton, Button } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const cases = [
  {
    title: 'Sikandar & Co. vs Federation of Pakistan ',
    section: 'PLD',
    courtName: 'Karachi High Court',
    year: '2021',
    description: 'What is case about short description or tags',
    judgement:
      'Torem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.',
    detail:
      'Torem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.',
  },
  {
    title: 'Case Title 2',
    section: 'PLD',
    courtName: 'Karachi High Court',
    year: '2023',
    description: 'This is a brief description of case 2.',
    judgement:
      'Torem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.',
    detail:
      'This is the detailed description of case 2. It may take up to 5 to 6 lines, providing comprehensive information about the case.',
  },
];

const CaseDetailSidebar = () => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate('/app/case-detail');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1.2,
        minWidth: '350px',
        p: '8px 8px 0 8px',
        bgcolor: '#F5F5F5',
        overflow: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton
          sx={{
            color: '#213165',
            fontSize: '24px',
          }}
          onClick={() => navigate('/app/case-law')}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Back
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Relevant Cases
      </Typography>
      {cases.map((caseItem, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 2,
            boxShadow: 1,
            border: '0.5px solid rgba(2, 22, 87, 1)',
            borderRadius: '12px',
            bgcolor: '#f5f5f5',
            width: '99%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: '#213165',
              color: '#fff',
              p: 2,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <Typography variant="h6" sx={{ flex: 1 }}>
              {caseItem.title}
            </Typography>
            <IconButton sx={{ color: '#fff' }}>
              <BookmarkIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'space-around',
              color: '#021657',
              p: 1,
            }}
          >
            <Typography variant="caption">{caseItem.section}</Typography>
            <Typography variant="caption">
              &bull; {caseItem.courtName}
            </Typography>
            <Typography variant="caption">&bull; {caseItem.year}</Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: '#021657', mb: 1 }}>
              <b>&bull; Judgement</b>
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 1)' }}>
              {caseItem.judgement}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              m: '0 8px 8px 8px',
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: 'none',
                bgcolor: '#213165',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
              onClick={handleReadMore}
            >
              Read more
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CaseDetailSidebar;
