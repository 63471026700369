import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 55;

const NavigationPane = () => {
  const [selectedItem, setSelectedItem] = useState('Legal Chat');
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'Legal Chat') {
      navigate('/app/legal-chat');
    } else if (item === 'Legal Search') {
      navigate('/app/case-law');
    } else if (item === 'Case Management') {
      navigate('/app/case-management');
    } else {
      navigate('/app/petition');
    }
  };

  useEffect(() => {
    if (location.pathname === '/app/legal-chat') {
      setSelectedItem('Legal Chat');
    } else if (
      location.pathname === '/app/case-law' ||
      location.pathname === '/app/case-detail'
    ) {
      setSelectedItem('Legal Search');
    } else if (location.pathname === '/app/case-management') {
      setSelectedItem('Case Management');
    } else {
      setSelectedItem('Petition Management');
    }
  }, [location]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          top: '64px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#101936',
          borderRight: '1px solid #ccc',
        },
      }}
    >
      <List>
        {[
          { label: 'Legal Chat', icon: <ChatIcon /> },
          { label: 'Legal Search', icon: <SearchIcon />, disabled: false },
          { label: 'Petition Management', icon: <AssignmentIcon /> },
          {
            label: 'Case Management',
            icon: <BusinessCenterIcon />,
            disabled: true,
          },
        ].map((item) => (
          <React.Fragment key={item.label}>
            <ListItem key={item.label} disablePadding>
              <Tooltip
                title={item.label}
                placement="bottom"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <ListItemButton
                  onClick={() => handleItemClick(item.label)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 2,
                    color:
                      selectedItem === item.label ? 'primary.main' : 'white',
                    backgroundColor:
                      selectedItem === item.label ? '#303f9f' : 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    borderRadius: 1,
                    padding: 1,
                  }}
                  disabled={item.disabled}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: 'white',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default NavigationPane;
