import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const CaseDetail = () => {
  const caseItem = {
    title: 'Sikandar & Co. vs Federation of Pakistan',
    section: 'PLD',
    courtName: 'Karachi High Court',
    year: '2021',
    description:
      'This case involves a legal dispute between Sikandar & Co. and the Federation of Pakistan regarding commercial regulations.',
    judgement:
      'The court ruled in favor of Sikandar & Co., stating that the regulations imposed by the Federation of Pakistan were unconstitutional.',
    detail:
      'The case details the legal arguments presented by both parties, the court proceedings, and the final judgment. It highlights the court’s reasoning and references to relevant laws and precedents.',
    moreInfo:
      'Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.',
    summary:
      'Sikandar & Co. challenged the Federation of Pakistan’s commercial regulations, resulting in a court ruling that deemed the regulations unconstitutional. The court’s decision was based on legal precedents and constitutional interpretations.',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90%',
        flex: 5,
        p: 2,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '90%',
          width: '100%',
          mt: '48px',
          boxShadow: '2px 4px 8px 0px #00000040',
          border: '0.5px solid rgba(2, 22, 87, 1)',
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            bgcolor: '#213165',
            color: '#fff',
            p: 3,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <Typography variant="h6">{caseItem.title}</Typography>
          <Typography variant="h6">|</Typography>
          <Typography variant="body2">{caseItem.courtName}</Typography>
          <Typography variant="h6">|</Typography>
          <Typography variant="subtitle2">{caseItem.year}</Typography>
        </Box>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" sx={{ mr: 2 }}>
              Case Description
            </Typography>
            <IconButton sx={{ color: '#213165' }}>
              <BookmarkIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {caseItem.description}
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Case Summary
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 1)', mb: 2 }}>
            {caseItem.summary}
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            More Information about the case
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 1)' }}>
            {caseItem.moreInfo}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseDetail;
