import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Divider,
  IconButton,
  Pagination,
  Chip,
  Stack,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const LegalSearch = ({ filters }) => {
  const itemsPerPage = 4;

  const [caseList, setCaseList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchClick = () => {
    setIsSearchOpen(true);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayedCases = caseList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleReadMore = () => {
    navigate('/app/case-detail');
  };

  const formatCaseTitle = (caseItem) => {
    const { Title } = caseItem;
    const splitTitle = Title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return { ...caseItem, Title: titleCaseTitle };
  };

  const formatCourtName = (courtName) => {
    return courtName
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await fetch('/api/cases');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const formattedCases = data.map(formatCaseTitle);
        setCaseList(formattedCases);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCases();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90%',
        flex: 5,
        p: 2,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '95%',
        }}
      >
        <Tabs
          value={selectedTab}
          centered
          onChange={handleTabChange}
          sx={{
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          <Tab
            label="Case Law"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          />
          <Tab
            label="Statutes"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          />
          <Tab
            label="Bookmarks"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
          />
        </Tabs>
        {isSearchOpen ? (
          <TextField
            variant="outlined"
            autoFocus
            placeholder="What are you looking for?"
            onBlur={handleSearchClose} // Close search on blur
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClose}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '60%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            <Typography>Search</Typography>
          </Box>
        )}
      </Box>
      {selectedTab === 0 && (
        <React.Fragment>
          {filters && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                p: '8px 8px 8px 16px',
              }}
            >
              <Typography variant="subtitle1">Applied Filters</Typography>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  mt: 1,
                  mb: 1,
                }}
              >
                {Object.entries(filters).map(([category, values]) =>
                  Array.isArray(values) ? (
                    values.map((value) => (
                      <Chip
                        key={`${category}-${value}`}
                        label={value}
                        sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                        color="primary"
                      />
                    ))
                  ) : (
                    <Chip
                      key={`${category}-${values}`}
                      label={category === 'Year' ? values.$y : values}
                      sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                      color="primary"
                    />
                  )
                )}
              </Stack>
              <Divider />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 1.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                gap: 3,
              }}
            >
              {displayedCases.map((caseItem, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%',
                    mb: 2,
                    boxShadow: 1,
                    border: '0.5px solid rgba(2, 22, 87, 1)',
                    borderRadius: '12px',
                    bgcolor: '#f5f5f5',
                    m: '1px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      bgcolor: '#213165',
                      color: '#fff',
                      p: 2,
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                      }}
                    >
                      {caseItem.Title}
                    </Typography>
                    <IconButton sx={{ color: '#fff' }}>
                      <BookmarkIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      color: '#021657',
                      p: 2,
                    }}
                  >
                    <Typography variant="body2">{caseItem.Section}</Typography>
                    <Typography variant="body2">
                      &bull; {formatCourtName(caseItem.Court)}
                    </Typography>
                    <Typography variant="body2">
                      &bull; {caseItem.Year}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: '#021657', mb: 1 }}
                    >
                      <b>&bull; Judgement</b>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1, color: 'rgba(0, 0, 0, 1)' }}
                    >
                      Will be added yet.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: '#021657', mb: 1 }}
                    >
                      <b>&bull; Description </b>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: 'rgba(0, 0, 0, 1)',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                      }}
                    >
                      {caseItem.Case_Description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: 'none',
                        bgcolor: '#213165',
                        '&:hover': {
                          backgroundColor: '#303f9f',
                        },
                      }}
                      onClick={handleReadMore}
                    >
                      Read more
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '80%',
                mt: 1,
              }}
            >
              <Pagination
                count={Math.ceil(caseList.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  mt: 2,
                  '& .MuiPaginationItem-root': {
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#213165',
                      '&:hover': {
                        backgroundColor: '#303f9f',
                      },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </React.Fragment>
      )}
      {selectedTab === 1 && (
        <Box>
          <Typography variant="h6">Statutes Content</Typography>
        </Box>
      )}
      {selectedTab === 2 && (
        <Box>
          <Typography variant="h6">Bookmarks here</Typography>
        </Box>
      )}
    </Box>
  );
};

export default LegalSearch;
