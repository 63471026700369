import { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Box, Stack, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Header from './components/layout/Header';
import Sidebar from './components/layout/Sidebar';
import ChatInterface from './components/ChatInterface';
import { initializeGA, trackPageView } from './analytics';
import NavigationPane from './components/layout/NavigationPane';
import NotFound from './components/NotFound';
import PetitionSidebar from './components/layout/PetitionSidebar';
import PetitionForm from './components/PetitionForm';
import LegalSearchSidebar from './components/layout/LegalSearchSidebar';
import LegalSearch from './components/LegalSearch';
import CaseDetailSidebar from './components/layout/CaseDetailSidebar';
import CaseDetail from './components/CaseDetail';
//import PetitionPage from './components/PetitionPage';

const queryClient = new QueryClient();
initializeGA();

function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const location = useLocation();

  const handleAppliedFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <Box>
        <Header />
        <Stack direction="row" height="calc(100vh - 64px)">
          {isSidebarVisible && location.pathname === '/app/legal-chat' && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              sx={{
                '& .MuiDrawer-paper': {
                  width: '80%',
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
              onClose={toggleSidebarVisibility}
            >
              <Sidebar isVisible={isSidebarVisible} />
            </Drawer>
          )}
          <NavigationPane />
          {location.pathname === '/app/legal-chat' && <Sidebar />}
          {location.pathname === '/app/petition' && <PetitionSidebar />}
          {location.pathname === '/app/case-law' && (
            <LegalSearchSidebar handleFilters={handleAppliedFilters} />
          )}
          {location.pathname === '/app/case-detail' && <CaseDetailSidebar />}
          {location.pathname === '/app/legal-chat' && (
            <ChatInterface toggleSidebar={toggleSidebarVisibility} />
          )}
          {location.pathname === '/app/case-management' && <NotFound />}
          {location.pathname === '/app/petition' && <PetitionForm />}
          {location.pathname === '/app/case-law' && (
            <LegalSearch filters={appliedFilters} />
          )}
          {location.pathname === '/app/case-detail' && <CaseDetail />}
        </Stack>
      </Box>
    </QueryClientProvider>
  );
}

export default App;
