import React from 'react';
import {
  Box,
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  List,
  Autocomplete,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const Facet = ({
  facet,
  openFacets,
  handleToggleFacet,
  handleChange,
  selectedValues,
}) => {
  return (
    <Box key={facet.category} sx={{ mb: 2 }}>
      <ListItem button onClick={() => handleToggleFacet(facet.category)}>
        <ListItemText primary={facet.category} />
        {openFacets[facet.category] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openFacets[facet.category]} timeout="auto" unmountOnExit>
        {facet.category === 'Sections' && (
          <List component="div" disablePadding>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pl: 4 }}>
              {facet.options.map((option) => (
                <Box key={option} sx={{ width: '50%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedValues[facet.category]?.includes(
                          option
                        )}
                        onChange={() => handleChange(facet.category, option)}
                      />
                    }
                    label={<Typography variant="body2">{option}</Typography>}
                  />
                </Box>
              ))}
            </Box>
          </List>
        )}
        {facet.category !== 'Sections' && facet.category !== 'Year' && (
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <Autocomplete
              size="small"
              value={selectedValues[facet.category] || ''}
              onChange={(event, newValue) =>
                handleChange(facet.category, newValue)
              }
              options={facet.options || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={facet.category}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '280px' }}
                />
              )}
            />
          </FormControl>
        )}
        {facet.category === 'Year' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                mt: 2,
                width: '280px',
                '& .MuiInputBase-input': {
                  padding: '9px 14px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              views={['year']}
              value={selectedValues[facet.category] || null}
              onChange={(newValue) => handleChange(facet.category, newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" fullWidth />
              )}
            />
          </LocalizationProvider>
        )}
      </Collapse>
    </Box>
  );
};

export default Facet;
