import config from '../config/api';

export const getPetitionHistory = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: 'PETITION_HISTORY_REQUEST',
    });
    const endpoint = `${config.petitionUrl}/get_petition`;
    const data = { user_id: userId, chat_id: chatId };
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'PETITION_HISTORY_SUCCESS',
        payload: responseData,
      });
    } else {
      console.log('Chat history not found');
    }
  } catch (error) {
    dispatch({
      type: 'PETITION_HISTORY_FAIL',
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const editPetitionTitle =
  (userId, chatId, newTitle) => async (dispatch) => {
    try {
      dispatch({
        type: 'EDIT_PETITION_TITLE_REQUEST',
      });
      const endpoint = `${config.petitionUrl}/edit_petition_title`;
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          user_id: userId,
          chat_id: chatId,
          chat_title: newTitle,
        }),
      });
      if (response.ok) {
        const responseData = await response.text();
        console.log(responseData);
        dispatch({
          type: 'EDIT_PETITION_TITLE_SUCCESS',
          payload: responseData.message,
        });
        console.log('Title updated');
      } else {
        console.log('Title not found');
      }
    } catch (error) {
      dispatch({
        type: 'EDIT_PETITION_TITLE_FAIL',
        payload: error.response?.data?.message ?? error.message,
      });
    }
  };

export const deletePetition = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: 'DELETE_PETITION_REQUEST',
    });
    const endpoint = `${config.petitionUrl}/delete_petition`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'DELETE_PETITION_SUCCESS',
        payload: responseData.message,
      });
      console.log('Chat deleted');
    } else {
      console.log('Chat not found');
    }
  } catch (error) {
    dispatch({
      type: 'DELETE_PETITION_FAIL',
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const getPetitionTitles = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_PETITION_TITLES_REQUEST',
    });
    const endpoint = `${config.petitionUrl}/get_init_petitions`;

    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ user_id: userId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'GET_PETITION_TITLES_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_PETITION_TITLES_FAIL',
      payload: 'Failed to fetch chat titles.',
    });
  }
};
